.hidden {
  display: none;
}

.App ::-webkit-scrollbar {
  -webkit-appearance: none!important;
  width: 6px
}

.App ::-webkit-scrollbar-thumb {
  background-color: var(--black-transparent-20,rgba(0,0,0,.2));
  border-radius: 4px
}

.App::-webkit-input-placeholder {
  color: #bfbfbf
}

.App:-ms-input-placeholder, .App::-ms-input-placeholder {
  color: #bfbfbf
}

.App::placeholder {
  color: #bfbfbf
}

.App {
  height: 100%
}

.not-selectable {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome and Opera */
}