@import "pdc.css";

* {
  -webkit-overflow-scrolling: touch;
}

body {
  margin: 0;
  padding: 0;
  overflow: hidden;
  font-size: 14px;
}
